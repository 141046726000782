/* src/components/BibleStudyTools.css */

/* Container */
.bible-study-container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
}

/* Header Section */
.bible-study-header {
  text-align: center;
  margin-bottom: 30px;
}

.bible-study-heading {
  font-size: 2.5rem;
  color: var(--primary-color); /* Green theme color */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bible-study-icon {
  margin-right: 10px;
  font-size: 2.5rem;
  color: var(--primary-color); /* Green theme color */
}

.bible-study-intro {
  font-size: 1.2rem;
  color: var(--text-color);
}

/* Search Section */
.bible-study-search {
  margin-bottom: 30px;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.form-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between inputs */
  flex-wrap: wrap;
}

.search-input,
.version-select,
.keywords-input {
  padding: 10px 15px;
  border: 2px solid var(--secondary-color); /* Light blue border */
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  flex: 1 1 200px; /* Allow flexibility */
}

.search-input:focus,
.version-select:focus,
.keywords-input:focus {
  border-color: var(--primary-color); /* Green on focus */
}

.cta-button {
  padding: 10px 20px;
  background-color: var(--primary-color); /* Green background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  margin-top: 10px; /* Space for wrapped buttons */
}

.cta-button:hover {
  background-color: var(--primary-color-dark); /* Darker green on hover */
}

/* Status Messages */
.bible-study-status {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 30px;
}

.bible-study-status.error {
  color: var(--error-color); /* Red for errors */
}

.bible-study-status.loading::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 4px solid var(--secondary-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Bible Passage Display */
.bible-study-display {
  margin-top: 20px;
}

.bible-text {
  font-size: 1.2rem;
  color: var(--text-color);
  line-height: 1.6;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
}

.bible-text p {
  margin-bottom: 10px;
  position: relative;
}

.verse {
  font-weight: bold;
  color: var(--primary-color);
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

/* Copy Button */
.copy-button {
  margin-left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.2s ease;
}

.copy-button:hover {
  transform: scale(1.2);
}

/* Bookmark Button */
.bookmark-button {
  margin-left: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--primary-color);
  transition: transform 0.2s ease, color 0.2s ease;
}

.bookmark-button:hover {
  transform: scale(1.2);
  color: var(--primary-color-dark);
}

/* Bookmarks Section */
.bookmarks-section {
  margin-top: 30px;
  text-align: left;
}

.bookmarks-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.bookmarks-list {
  list-style-type: none;
  padding: 0;
}

.bookmarks-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bookmark-link {
  background-color: var(--secondary-color);
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  text-align: left;
  flex: 1;
}

.bookmark-link:hover {
  background-color: var(--primary-color);
}

.remove-bookmark {
  background: none;
  border: none;
  color: var(--error-color);
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.remove-bookmark:hover {
  color: #c62828; /* Darker red on hover */
}

/* Navigation Link Back */
.navigation-link-back {
  margin-top: 30px;
}

.navigation-link-back .cta-button {
  display: inline-flex;
  align-items: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .bible-study-heading {
    font-size: 2rem;
  }

  .bible-study-intro {
    font-size: 1rem;
  }

  .search-input,
  .version-select,
  .keywords-input {
    flex: 1 1 100%;
  }

  .cta-button {
    width: 100%;
  }

  .bible-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .bible-study-container {
    width: 95%;
    padding: 20px 10px;
  }

  .bible-study-heading {
    font-size: 1.8rem;
  }

  .bible-study-intro {
    font-size: 0.9rem;
  }

  .search-form {
    flex-direction: column;
    align-items: stretch;
  }

  .form-group {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .search-button {
    margin-left: 0;
  }

  .bible-text {
    font-size: 0.9rem;
  }
}
