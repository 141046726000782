/* src/components/HamburgerMenu.css */

.hamburger-menu {
  position: fixed;
  top: 1px;
  left: 10px;
  z-index: 1003; /* Above other elements */
}

.menu-button {
  background: none;
  border: none;
  color: #c00404;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}

.menu-button:hover {
  color: #00796b;
}

/* Navigation Links */
.nav-links {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  width: 250px;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 60px;
  transition: left 0.3s ease-in-out;
  z-index: 1002; /* Below the hamburger button */
  overflow-y:auto ;
}

.nav-links.open {
  left: 0;
}

.nav-links a {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  text-decoration: none;
  font-size: 1.2rem;
  color: #333;
  width: 100%;
}

.nav-links a:hover {
  background-color: #e0f7fa;
  color: #00796b;
}

.nav-icon {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #00796b;
}


.nav-icondev {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #888a89;
}



.nav-icon:hover {
   color: #c10505;
}
