/* src/components/Blog.css */

/* Container */
.blog-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
}

/* Header Section */
.blog-header {
  text-align: center;
  margin-bottom: 40px;
}

.blog-heading {
  font-size: 2.5rem;
  color: #00796b; /* Green theme color */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-icon {
  margin-right: 10px;
  font-size: 2.5rem;
  color: #00796b; /* Green theme color */
}

.blog-intro {
  font-size: 1.2rem;
  color: #424242; /* Dark gray */
}

/* Blog Embed */
.blog-embed {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* White background for contrast */
  border: 2px solid #d1eeeb; /* Light border to define the area */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-embed:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.blog-iframe {
  width: 100%;
  height: 80vh; /* Adjusted height for better visibility */
  border: none; /* Remove default iframe border */
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 992px) {
  .blog-heading {
    font-size: 2rem;
  }

  .blog-intro {
    font-size: 1rem;
  }

  .blog-iframe {
    height: 70vh;
  }
}

@media (max-width: 768px) {
  .blog-heading {
    font-size: 1.8rem;
  }

  .blog-intro {
    font-size: 0.95rem;
  }

  .blog-iframe {
    height: 60vh;
  }
}

@media (max-width: 480px) {
  .blog-container {
    width: 95%;
    padding: 20px 0;
  }

  .blog-heading {
    font-size: 1.6rem;
  }

  .blog-intro {
    font-size: 0.9rem;
  }

  .blog-iframe {
    height: 50vh;
  }
}
