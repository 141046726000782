/* src/components/Events.css */

/* Container */
.events-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
}

/* Header Section */
.events-header {
  text-align: center;
  margin-bottom: 40px;
}

.events-heading {
  font-size: 3rem;
  color: var(--primary-color); /* Green theme color */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.events-icon {
  margin-right: 10px;
  font-size: 3rem;
  color: var(--primary-color); /* Green theme color */
}

.events-intro {
  font-size: 1.2rem;
  color: var(--text-color);
}

/* Events Calendar */
.events-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjusted height for better visibility */
  padding: 20px;
  background-color: #ffffff; /* White background for contrast */
  border: 2px solid #90caf9; /* Light blue border */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.events-calendar:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.events-iframe {
  width: 100%;
  height: 100%;
  border: none; /* Remove default iframe border */
  border-radius: 8px;
}

/* Navigation Link Back */
.navigation-link-back {
  margin-top: 30px;
  text-align: center;
}

.navigation-link-back .cta-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--primary-color); /* Green background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.navigation-link-back .cta-button:hover {
  background-color: var(--primary-color-dark); /* Darker green on hover */
}

.back-icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 992px) {
  .events-heading {
    font-size: 2.5rem;
  }

  .events-intro {
    font-size: 1rem;
  }

  .events-calendar {
    height: 50vh;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .events-heading {
    font-size: 2rem;
  }

  .events-intro {
    font-size: 0.95rem;
  }

  .events-calendar {
    height: 45vh;
    padding: 10px;
  }

  .events-iframe {
    height: 100%;
  }
}

@media (max-width: 480px) {
  .events-container {
    width: 95%;
    padding: 20px 10px;
  }

  .events-heading {
    font-size: 1.8rem;
  }

  .events-intro {
    font-size: 0.9rem;
  }

  .events-calendar {
    height: 40vh;
    padding: 5px;
  }
}
