html, body {
    margin:0; padding:0; height:100%;
    background-color: black; color:white;
    font-family: Arial,sans-serif;
  }
  body {
    display:flex; flex-direction:column; justify-content:center;
  }




  /* Basic reset and styles */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: black;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  #lyrics-container {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    background-color: black;
  }
  
  #logo {
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(147, 146, 146);
    font-size: 16px;
    padding: 10px;
    z-index: 1001;
  }
  
  
  
  
  
  .controls-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    
  }
  
  button, input[type="range"], select {
    font-size: 12px;
    padding: 5px;
    margin: 5px;
    background-color: black;
    color: #999;
    border: none;
    border-radius: 3px;
  }
  
  #lyrics-box {
    padding: 65px;
    flex: 1;
    word-wrap: break-word;
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  #lyrics {
    font-size: 40px;
    white-space: normal;
    word-wrap: break-word;
  }
  
  .tile {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    aspect-ratio: 4 / 3;
  }
  
  .tile:hover {
    background-color: #FFD700;
  }
  
  #slide-prev-btn, #slide-next-btn, #slide-bottom-play-btn {
    position: absolute;
    bottom: 150px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 50%;
    opacity: 0.9;
  }
  
  #slide-prev-btn:hover, #slide-next-btn:hover, #slide-bottom-play-btn:hover {
    opacity: 1;
  }
  
  #video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 999;
  }
  
  iframe {
    width: 100%;
    height: 100%;
  }
  

  @media (max-width:600px){
    /* Adjust widths of various controls */
    #logo, #song-selector, #lines-per-slide-range, #font-range,
    #blogspot-selector, #display-mode-selector, #search-input, #category-selector{
      width:100%;
      top: 0px;
      font-size: 40px;
     }

    /* Ensure controls do not overlap with the logo */
    #controls {
      flex-direction: column;
      align-items: flex-start;
      /* top: 6%; Positioned below the logo */
      right: 40; /* Keep it aligned to the right */
    }

    /* Configure control rows for mobile */
    .controls-row.top-row,
    .controls-row.bottom-row {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
     
      width: 90%;
      margin-right: 20px;
 
    }

    /* Add spacing between the control rows */
    .controls-row.top-row {
      margin-bottom: 10px;
     
            
    }
      


    /* Reduce padding and margins for better fit on mobile */
    #controls select, #controls button, #controls input[type="text"]{
      padding:2px;
      margin:2px;
    }

    /* Style the H button to be always visible and positioned correctly */
    #hide-controls-btn {
      position: fixed;
      top: 10px; /* Adjust as needed to avoid overlapping with the logo */
      right: 10px; /* Adjust based on desired placement */
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      border-radius: 3px;
      padding: 5px 10px;
      cursor: pointer;
      z-index: 1002; /* Ensure it's above other elements */
    }

    #hide-controls-btn:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  #lyrics {
    word-wrap: break-word; white-space: normal;
    font-size:40px; padding:20px;
  }
  @media(max-width:1024px){
    #lyrics{ font-size:60px; }
    #lyrics-box{ font-size:35px; }

  }
  @media(max-width:600px){
    #lyrics{ font-size:40px; }
    #lyrics-box{ font-size:25px; }

  }
  @media(max-width:400px){
    #lyrics{ font-size:30px; }
    #lyrics-box{ font-size:15px; }

  }

  #lyrics-container{
    text-align:center; width:100%; height:100%;
    display:flex; flex-direction:column; justify-content:flex-start;
    position:relative; background:black;
  }
  #lines-per-slide-value, #font-size-value {
    margin-left:5px; font-weight:bold;
  }
  #lyrics-box {
    padding:10px; flex:1; display:none;
    justify-content:center; align-items:center; position:relative;
  }
  #controls {
    display:flex; justify-content:flex-end; align-items:center;
    background:black; padding:5px; z-index:1000; width:97%;
    font-size:14px; position:absolute; top:2; right:20;
  }
  #controls select, #controls button, #controls input[type="text"]{
    font-size:14px; padding:3px; margin:3px; color:#999;
    background-color:black; border:none; border-radius:3px;
  }
  #youtube-selector { width:40px; }
  #slide-play-pause-btn {
    font-size:16px; cursor:pointer; background:#555; border:none;
    border-radius:3px; padding:3px 8px; color:white; margin-left:10px; display:none;
  }
  .title-slide { color:yellow; font-weight:bold; }
  .english-lyrics { color:yellow; }
  .non-english-lyrics { color:white; }

  #video-container, #music-container {
    position:absolute; top:0; left:0; width:100%; height:100%;
    display:none; z-index:999; overflow:hidden;
  }
  #video-container iframe, #music-container iframe {
    width:100%; height:100%;
  }
  #music-container {
    display:flex; flex-direction:row; justify-content:center; align-items:flex-start;
    padding:20px; box-sizing:border-box; overflow:auto;
  }
  #music-embed-left {
    width:48%; height:98%; margin:1%; box-sizing:border-box;
  }
  #music-content-right {
    width:48%; height:98%; margin:1%; box-sizing:border-box; overflow-y:auto;
    text-align:left; background-color:#111; padding:10px; border-radius:5px;
  }

  .music-nav-button {
    position:absolute; bottom:20px; background:rgba(0,0,0,0.5);
    border:none; color:white; font-size:30px; cursor:pointer; padding:10px;
    border-radius:50%; opacity:0.7; z-index:1000;
  }
  .music-nav-button:hover { opacity:1; }
  #music-prev-btn { left:20px; }
  #music-next-btn { right:20px; }

  #hidden-audio-player {
    position:absolute; left:-9999px; width:200px; height:200px;
    visibility:hidden;
  }
  .slide-nav-button {
    position:absolute; bottom:150px; background:rgba(0,0,0,0);
    border:none; color:white; font-size:24px; cursor:pointer; padding:50px;
    border-radius:50%; opacity:0.9; z-index:1000; transform:translateY(50%);
    display:none;
  }
  .slide-nav-button:hover{ opacity:1; }
  #slide-prev-btn {
    left:50%; transform:translate(-125%,50%);color:#00796b; bottom:100px;  font-size:50px; 
  }
  #slide-next-btn {
    left:50%; transform:translate(25%,50%);color:#00796b; bottom:100px; font-size:50px;  
  }


  #slide-prev-song-btn {
    left:50%; transform:translate(-175%,50%);color:#0e675c; bottom:100px;  font-size:50px; 
  }
  #slide-next-song-btn {
    left:50%; transform:translate(75%,50%);color:#0e675c; bottom:100px; font-size:50px;  
  }


  .entire-song {
    display:block; overflow:auto; padding:20px; font-size:20px;
    line-height:1.4; text-align:left;
  }
  #playback-rate-display{ color:#ccc; margin-left:10px; }

  #presenter-mode-container {
    display:none; width:98%; height:98%;
    flex-direction:column; justify-content:flex-start; align-items:center;
  }
  #presenter-current, #presenter-next {
    width:100%; display:flex; justify-content:center; align-items:center;
    white-space:pre-wrap; padding:10px; box-sizing:border-box;
  }
  #presenter-current{ flex:0.60; }
  #presenter-next{ flex:0.40; color:grey; }

  #watermark {
    position:absolute; top:50%; left:50%; transform:translate(-50%,-50%);
    font-size:10px; color:rgba(211,38,38,0.07);
    pointer-events:none; user-select:none; z-index:1;
  }
  #slide-bottom-play-btn {
    position:absolute; bottom:100px; background:#00796b;
    border:none; color:white; font-size:24px; cursor:pointer; padding:30px;
    border-radius:50%; opacity:0.9; z-index:1000; transform:translateY(50%);
    display:none;
  }
  #slide-bottom-play-btn:hover{ opacity:1; background:#01ca12;}
  #slide-bottom-play-btn {
    left:50%; transform:translate(-50%,50%);
  }

  #tiles-container {
    display:none; flex-wrap:wrap; justify-content:center; align-items:center;
    padding:10px 10.5px 0 10.5px; width:100%; box-sizing:border-box; overflow-y:auto;
  }
  .tile {
    background-color:white; color:black; padding:10px; margin:10px;
    border-radius:15px; cursor:pointer;
    transition: background-color 0.5s ease, color 0.5s ease;
    width:200px; height:100px; display:flex; justify-content:center; align-items:center;
    text-align:center; box-sizing:border-box; aspect-ratio:4/3;
  }
  .tile:hover {
    background-color:#FFD700; color:black;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  @media (max-width:600px){
    .tile {
      width:80px; height:80px; padding:10px; margin:5px; font-size:12px;
    }
  }
  #back-to-songs-btn {
    background-color:#4262cb; color:rgb(252,244,244); border:none;
    border-radius:3px; padding:10px 20px; cursor:pointer; margin:10px auto;
    display:none; transition: background-color 0.3s, color 0.3s;
  }
  #back-to-songs-btn:hover {
    background-color:yellow; color:black;
  }

  .modal {
    display:none; position:fixed; z-index:2000; left:0; top:0; width:100%; height:100%;
    overflow:auto; background-color:rgba(0,0,0,0.8);
  }
  .modal-content {
    background-color:#222; margin:10% auto; padding:20px; border:1px solid #888;
    width:80%; color:#fff; border-radius:5px; position:relative;
  }
  .close {
    color:#aaa; position:absolute; top:10px; right:25px; font-size:30px;
    font-weight:bold; cursor:pointer;
  }
  .close:hover, .close:focus{ color:#fff; text-decoration:none; cursor:pointer; }
  .shortcuts-list { list-style:none; padding:0; }
  .shortcuts-list li { margin:10px 0; }
  .shortcut-key { font-weight:bold; color:#4CAF50; }

  /* Always Visible H Button */
  #hide-controls-btn {
    position: fixed;
    top: 10px; /* Adjust based on your logo's height */
    right: 10px; /* Adjust to position it appropriately */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 1002; /* Ensure it's above other elements */
  }

/* Always Visible Controls on Mobile */
.mobile-always-visible {
  display: block !important; /* Override any inline styles */
  position: fixed; /* Keep them in place */
  z-index: 1001; /* Below the hide-controls-btn */
}

/* Mobile-Specific Styling */
@media (max-width: 768px) {
  .mobile-always-visible {
    display: block !important; /* Ensure visibility on mobile */
  }
}


  #hide-controls-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  #logo {
    position:absolute; top:5px; left:20px; color:rgb(147,146,146);
    background-color:black; font-size:16px; padding:10px; z-index:1001;
  }

  #watermark0 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    color: rgba(98, 96, 96, 0.3); /* Dark blackish transparent color */
    font-weight: bold;
    pointer-events: none;
    user-select: none;
    z-index: 1;
}


#watermark {
  position:fixed;
   left : 5%;
  font-size: 140px;
  color: rgba(64, 63, 63, 0.3); /* Dark blackish transparent color */
  font-weight: bold;
  pointer-events: none;
  transform: rotate(-35deg);
  width: 100%;

  user-select: none;
  z-index: 1;
}


 

 


 