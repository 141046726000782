/* src/components/Mentorship.css */

.mentorship-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .mentorship-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .mentorship-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mentorship-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .mentorship-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Information Section */
  .mentorship-info {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .mentorship-info h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .mentorship-info p {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  /* Mentor Nomination */
  .mentor-nomination, .mentee-enrollment {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .mentor-nomination h2, .mentee-enrollment h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .mentor-nomination p, .mentee-enrollment p {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 20px;
  }
  
  .nominate-button, .enroll-button {
    padding: 12px 30px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .nominate-button:hover, .enroll-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  /* How It Works */
  .mentorship-how-it-works {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .mentorship-how-it-works h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .mentorship-how-it-works ol {
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .mentorship-how-it-works li {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 10px;
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .mentorship-heading {
      font-size: 2.5rem;
    }
  
    .mentorship-intro {
      font-size: 1rem;
    }
  
    .mentorship-info h2, .mentor-nomination h2, .mentee-enrollment h2, .mentorship-how-it-works h2 {
      font-size: 1.8rem;
    }
  
    .mentorship-how-it-works ol {
      padding-left: 20px;
    }
  
    .nominate-button, .enroll-button {
      padding: 10px 25px;
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .mentorship-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .mentorship-heading {
      font-size: 1.8rem;
    }
  
    .mentorship-intro {
      font-size: 0.9rem;
    }
  
    .mentorship-info h2, .mentor-nomination h2, .mentee-enrollment h2, .mentorship-how-it-works h2 {
      font-size: 1.5rem;
    }
  
    .mentorship-how-it-works ol {
      padding-left: 15px;
    }
  
    .nominate-button, .enroll-button {
      width: 100%;
      padding: 10px;
      font-size: 0.9rem;
    }
  }
  