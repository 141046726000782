/* src/components/LiveStreaming.css */

.live-streaming-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .live-streaming-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .live-streaming-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .live-streaming-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .live-streaming-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* YouTube Embed */
  .youtube-embed {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .youtube-embed iframe {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .live-streaming-heading {
      font-size: 2.5rem;
    }
  
    .live-streaming-intro {
      font-size: 1rem;
    }
  
    .youtube-embed iframe {
      height: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .live-streaming-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .live-streaming-heading {
      font-size: 1.8rem;
    }
  
    .live-streaming-intro {
      font-size: 0.9rem;
    }
  
    .youtube-embed iframe {
      height: 300px;
    }
  
    .navigation-link-back .cta-button {
      width: 100%;
      justify-content: center;
    }
  }
  