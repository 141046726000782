/* src/components/Counseling.css */

.counseling-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .counseling-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .counseling-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .counseling-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .counseling-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Counseling Types */
  .counseling-types {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .counseling-types h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .counseling-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .counseling-card {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .counseling-title {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .counseling-description {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  /* How We Can Help */
  .counseling-how-we-help {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .counseling-how-we-help h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .counseling-how-we-help p {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 20px;
  }
  
  .connect-button {
    padding: 12px 30px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .connect-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .counseling-heading {
      font-size: 2.5rem;
    }
  
    .counseling-intro {
      font-size: 1rem;
    }
  
    .counseling-types h2,
    .counseling-how-we-help h2 {
      font-size: 1.8rem;
    }
  
    .counseling-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 15px;
    }
  
    .counseling-card {
      padding: 15px;
    }
  
    .counseling-title {
      font-size: 1.3rem;
    }
  
    .counseling-description {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .counseling-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .counseling-heading {
      font-size: 1.8rem;
    }
  
    .counseling-intro {
      font-size: 0.9rem;
    }
  
    .counseling-types h2,
    .counseling-how-we-help h2 {
      font-size: 1.5rem;
    }
  
    .counseling-grid {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    .counseling-card {
      padding: 10px;
    }
  
    .counseling-title {
      font-size: 1.2rem;
    }
  
    .counseling-description {
      font-size: 0.85rem;
    }
  
    .connect-button {
      width: 100%;
    }
  }
  