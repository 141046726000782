/* BibleReader.css */

/* 1) Let html/body fill the window and allow scrolling if needed */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #000; /* Black background */
    color: #f0f0f0; /* Light white text */
    font-family: Arial, sans-serif;
}

/* 2) Main root container. Flex column. */
.br-bible-reader-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures full page height */
    background-color: #000; /* Black background */
    color: #f0f0f0; /* Light white text */
}

/* 3) The bible-container also fills space as a flex column. */
#br-bible-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    /* No margin/padding at top so controls appear flush */
    margin: 0;
    padding: 5px;
}

/* 4) The controls bar: sticky at the top, no extra spacing */
#br-controls {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(17, 17, 17, 0.95); /* Slightly transparent dark background */
    padding: 8px 16px;  /* Minor padding for breathing room */
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);

    display: flex;
    flex-wrap: wrap; /* Allow controls to wrap on narrow screens */
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

/* If you want to hide controls, you can still toggle this class */
#br-controls.br-hidden {
    display: none;
}

/* Groups inside the controls */
.br-controls-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allow group items to wrap if needed */
}

/* Toggle Controls Button */
#br-toggle-controls-btn {
    position: fixed;
    top: 10px;
    right: 15px;
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
    color: #f0f0f0; /* Light white text */
    border: none;
    padding: 8px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 1100;
    opacity: 0.7;
    transition: opacity 0.3s;
}

#br-toggle-controls-btn:hover {
    opacity: 1;
}

/* Basic input/select styling */
#br-controls select,
#br-controls input[type="text"] {
    font-size: 14px;
    padding: 5px 8px;
    margin: 0 5px;
    color: #f0f0f0; /* Light white text */
    background-color: #333; /* Dark background for inputs */
    border: none;
    border-radius: 4px;
    min-width: 80px; /* Slightly smaller min-width so they shrink more easily */
}

/* The content box: everything below the controls */
#br-content-box {
    display: flex;
    flex-direction: column;
    flex: 1;           /* Fill remaining vertical space */
    overflow-y: auto;  /* Scroll if verses exceed screen */
    padding: 10px 20px; /* Some side padding */
}

/* Highlighted verse styling */
.br-highlighted {
    color: yellow;
    background-color: rgba(255,255,0,0.2);
}

.br-word-highlight {
    color: yellow;
    background-color: rgba(255, 255, 0, 0.2);
    /* Or any styling you prefer */
}

/* 5) The yellow title below controls, centered */
#br-title {
    color: #f1c40f; /* Yellow color */
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Responsive text styling */
.br-responsive-text {
    margin: 10px 0;
    line-height: 1.4;
    word-wrap: break-word;
    
    /* Combine both vw + vh for dynamic resizing */
    font-size: calc(2vw + 2.5vh);
}

/* Verse styling with a responsive font size */
.br-verse {
    margin: 10px 0;
    line-height: 1.4;
    word-wrap: break-word;
    
    /* Combine both vw + vh for dynamic resizing */
    font-size: calc(2vw + 2.5vh);
    color: #f0f0f0; /* Light white text */
}

.br-verse-number {
    color: yellow;
    margin-right: 5px;
    font-weight: bold;
}

/* Navigation buttons at bottom/fixed */
.br-nav-button {
    position: fixed;
    bottom: 20px;
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
    border: none;
    color: #f0f0f0; /* Light white text */
    font-size: 24px;
    cursor: pointer;
    padding: 15px;
    border-radius: 50%;
    opacity: 0.7;
    transition: opacity 0.3s;
    z-index: 1000;
}

.br-nav-button:hover {
    opacity: 1;
}

#br-prev-btn {
    left: 45%;bottom:100;
    transform: translateX(-50%);font-size: 50px;
}

#br-next-btn {
    left: 55%; bottom:100;
    transform: translateX(-50%);font-size: 50px;
}

/* Play/Pause Button */
#br-play-pause-btn {
    background-color: #555; /* Dark gray background */
    color: #f0f0f0; /* Light white text */
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
}

#br-play-pause-btn:hover {
    background-color: #666; /* Slightly lighter gray on hover */
}

/* Playback Rate Display */
#br-playback-rate-display {
    margin-left: 5px;
    font-size: 12px;
    color: #f0f0f0; /* Light white text */
}

/* Slider Container */
#br-slider-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: #f0f0f0; /* Light white text */
}

#br-lines-slider {
    width: 150px;
    margin: 0 10px;
}

#br-slider-value {
    width: 30px;
    text-align: center;
    color: #f0f0f0; /* Light white text */
}

/* Share button */
#br-share-btn {
    background-color: #2980b9; /* Blue background */
    color: #f0f0f0; /* Light white text */
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
}

#br-share-btn:hover {
    background-color: #3498db; /* Lighter blue on hover */
}

/* Help button */
#br-help-btn {
    background-color: #8e44ad; /* Purple background */
    color: #f0f0f0; /* Light white text */
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 18px;
    margin-left: 5px;
}

#br-help-btn:hover {
    background-color: #9b59b6; /* Lighter purple on hover */
}

/* Tooltip */
.br-tooltip {
    position: relative;
    display: inline-block;
}

.br-tooltip .br-tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: #555; /* Dark gray for tooltip */
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1001;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
}

.br-tooltip .br-tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%; /* Bottom of tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent; /* Arrow color matches tooltip */
}

.br-tooltip:hover .br-tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Help modal */
.br-help-modal {
    display: none; 
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgba(0,0,0,0.8); /* Semi-transparent black background */
}

.br-help-modal-content {
    background-color: #222; /* Dark background for modal */
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    color: #f0f0f0; /* Light white text */
    border-radius: 8px;
    position: relative;
}

.br-close-help {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

.br-close-help:hover,
.br-close-help:focus {
    color: #fff;
    cursor: pointer;
}

/* Autocomplete Active Item */
.br-autocomplete-active {
    background-color: #575757 !important; /* Darker background for active autocomplete */
    color: #f0f0f0; /* Light white text */
}

/* Hidden elements */
.br-hidden {
    display: none;
}

/* Autocomplete Items */
.br-autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 1002;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #333; /* Dark background */
}

.br-autocomplete-item {
    padding: 10px;
    cursor: pointer;
    background-color: #333; /* Dark background */
    color: #f0f0f0; /* Light white text */
}

.br-autocomplete-item:hover {
    background-color: #575757; /* Lighter dark on hover */
}

/* Verse Input */
#br-verse-input {
    background-color: #333; /* Dark background */
    color: #f0f0f0; /* Light white text */
    border: none;
    border-radius: 4px;
}

/* Modal scroll */
.br-help-modal-content {
    max-height: 80vh;
    overflow-y: auto;
}

/* Responsive Design */
@media (max-width: 600px) {
    #br-controls {
        flex-direction: column;
        align-items: flex-start;
    }

    .br-controls-group {
        width: 100%;
        margin-bottom: 8px; /* Spacing between groups */
        justify-content: flex-start;
    }

    #br-controls select,
    #br-controls input[type="text"] {
        width: 100%;
        max-width: none; /* Let them fill the line */
        margin: 4px 0;
    }
}

/* Responsive Navigation Buttons */
@media (max-width: 768px) {
    #br-controls select,
    #br-controls input[type="text"] {
        font-size: 12px;
    }

    .br-verse {
        font-size: calc(1.8vw + 2vh);
    }

    .br-responsive-text{
        font-size: calc(1.8vw + 2vh);
    }
}

@media (max-width: 480px) {
    .br-bible-reader-root {
        padding: 0 10px;
    }

    #br-title {
        font-size: 1.2rem;
    }

    .br-verse {
        font-size: calc(2vw + 2vh); /* Adjust as needed */
    }

    .br-responsive-text{
        font-size: calc(2vw + 2vh);
    }

    .br-nav-button {
        padding: 10px;
        font-size: 20px;
    }

    #br-lines-slider {
        width: 100px;
    }

    #br-slider-container {
        flex-direction: column;
        align-items: flex-start;
    }

    #br-share-btn, #br-help-btn {
        margin-left: 0;
        margin-top: 5px;
    }
}
