/* src/components/Profile.css */

.profile-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .profile-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .profile-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .profile-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Profile Information */
  .profile-info {
    display: flex;
    align-items: center;
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .profile-avatar {
    margin-right: 20px;
  }
  
  .avatar-icon {
    font-size: 6rem;
    color: var(--primary-color);
  }
  
  .profile-details h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .profile-details p {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 5px;
  }
  
  /* Achievements */
  .profile-achievements {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .profile-achievements h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .achievements-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .achievement-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .achievement-icon {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 5px;
  }
  
  .achievement-text {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  /* Benefits of Membership */
  .profile-benefits {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-benefits h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .profile-benefits ul {
    list-style-type: disc;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .profile-benefits li {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 10px;
  }
  
  /* Google Login Button */
  .profile-login {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .profile-login h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .login-button {
    display: inline-flex;
    align-items: center;
    padding: 12px 30px;
    background-color: #db4437; /* Google Red */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #c33d2e; /* Darker red on hover */
  }
  
  .login-icon {
    margin-right: 10px;
    font-size: 1.5rem;
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .profile-heading {
      font-size: 2.5rem;
    }
  
    .profile-intro {
      font-size: 1rem;
    }
  
    .profile-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .profile-info .profile-avatar {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .profile-details h2 {
      font-size: 1.8rem;
    }
  
    .profile-details p {
      font-size: 0.95rem;
    }
  
    .achievements-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .achievement-card {
      margin-bottom: 10px;
    }
  
    .profile-benefits h2, .profile-login h2 {
      font-size: 1.8rem;
    }
  
    .profile-benefits ul {
      padding-left: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .profile-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .profile-heading {
      font-size: 1.8rem;
    }
  
    .profile-intro {
      font-size: 0.9rem;
    }
  
    .profile-info h2 {
      font-size: 1.5rem;
    }
  
    .profile-info p {
      font-size: 0.85rem;
    }
  
    .achievements-grid {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  
    .achievement-card {
      margin-bottom: 5px;
    }
  
    .profile-benefits h2, .profile-login h2 {
      font-size: 1.5rem;
    }
  
    .profile-benefits ul {
      padding-left: 15px;
    }
  
    .login-button {
      width: 100%;
      padding: 10px;
      font-size: 0.9rem;
    }
  }
  