/* src/components/SmallGroups.css */

.small-groups-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .small-groups-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .small-groups-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .small-groups-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .small-groups-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Selection Form */
  .small-groups-form {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .form-field {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
  }
  
  .form-field label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-field select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-field select:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  
  /* Search Button */
  .search-button {
    padding: 12px 30px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0 auto;
  }
  
  .search-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .search-button:disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .small-groups-heading {
      font-size: 2.5rem;
    }
  
    .small-groups-intro {
      font-size: 1rem;
    }
  
    .form-group {
      flex-direction: column;
    }
  
    .form-field {
      flex: 1 1 100%;
    }
  }
  
  @media (max-width: 480px) {
    .small-groups-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .small-groups-heading {
      font-size: 1.8rem;
    }
  
    .small-groups-intro {
      font-size: 0.9rem;
    }
  
    .form-group {
      gap: 10px;
    }
  
    .form-field {
      flex: 1 1 100%;
    }
  
    .search-button {
      width: 100%;
    }
  }
  