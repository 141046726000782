/* src/components/Podcasts.css */

/* Container */
.podcasts-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
}

/* Header Section */
.podcasts-header {
  text-align: center;
  margin-bottom: 40px;
}

.podcasts-heading {
  font-size: 2.5rem;
  color: #00796b; /* Green theme color */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.podcasts-icon {
  margin-right: 10px;
}

.podcasts-intro {
  font-size: 1.2rem;
  color: #424242; /* Dark gray */
}

/* Search Form */
.search-form {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.search-input {
  width: 60%;
  max-width: 400px;
  padding: 10px 15px;
  border: 2px solid #90caf9; /* Light blue border */
  border-radius: 5px 0 0 5px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #00796b; /* Green on focus */
}

.search-button {
  padding: 10px 15px;
  border: 2px solid #90caf9; /* Light blue border */
  border-left: none;
  background-color: #00796b; /* Green background */
  color: #ffffff; /* White icon */
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #005b5b; /* Darker green on hover */
}

/* Status Messages */
.status-message {
  font-size: 1rem;
  color: #424242;
  margin-bottom: 20px;
}

.status-message.error {
  color: #e53935; /* Red for errors */
}

/* Podcasts List */
.podcasts-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.podcast-card {
  background-color: #f9f9f9; /* Light gray background */
  border: 2px solid #90caf9; /* Light blue border */
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.podcast-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.podcast-thumbnail {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.podcast-details {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.podcast-title {
  font-size: 1.5rem;
  color: #00796b; /* Green theme color */
  margin-bottom: 5px;
}

.podcast-author {
  font-size: 1rem;
  color: #424242; /* Dark gray */
  margin-bottom: 15px;
}

.podcast-description {
  font-size: 0.95rem;
  color: #424242; /* Dark gray */
  margin-bottom: 15px;
  flex: 1;
}

.episodes-list {
  margin-top: 10px;
  text-align: left;
}

.episodes-list h3 {
  font-size: 1.2rem;
  color: #00796b; /* Green theme color */
  margin-bottom: 10px;
}

.episode {
  margin-bottom: 15px;
}

.episode-title {
  font-size: 1rem;
  color: #00796b; /* Green theme color */
  margin-bottom: 5px;
}

.podcast-audio {
  width: 100%;
  outline: none;
}

.no-episodes {
  font-size: 0.9rem;
  color: #e53935; /* Red for no episodes */
}

/* Responsive Design */
@media (max-width: 768px) {
  .podcasts-heading {
    font-size: 2rem;
  }

  .podcasts-intro {
    font-size: 1rem;
  }

  .search-input {
    width: 70%;
  }

  .podcast-title {
    font-size: 1.3rem;
  }

  .podcast-description {
    font-size: 0.85rem;
  }

  .episodes-list h3 {
    font-size: 1.1rem;
  }

  .episode-title {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .podcasts-container {
    width: 95%;
    padding: 20px 0;
  }

  .podcasts-heading {
    font-size: 1.8rem;
  }

  .podcasts-intro {
    font-size: 0.9rem;
  }

  .search-input {
    width: 80%;
  }

  .podcast-title {
    font-size: 1.1rem;
  }

  .podcast-description {
    font-size: 0.8rem;
  }

  .episodes-list h3 {
    font-size: 1rem;
  }

  .episode-title {
    font-size: 0.85rem;
  }
}
