/* src/components/Karaoke.css */

.karaoke-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  padding: 60px 0 20px; /* Increased top padding to account for banner */
  background-color: #f5f5f5;
  color: #333;
}

/* Header Section */
.karaoke-header {
  text-align: center;
  margin-bottom: 40px;
}

.karaoke-heading {
  font-size: 3rem;
  color: var(--primary-color); /* Green theme color */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.karaoke-icon {
  margin-right: 10px;
  font-size: 3rem;
  color: var(--primary-color); /* Green theme color */
}

.karaoke-intro {
  font-size: 1.2rem;
  color: var(--text-color);
}

.playlist-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 90%;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Lowering the section below the banner */
}

.playlist-item {
  background-color: #ffffff;
  border: 2px solid #d1eeeb;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #00796b;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playlist-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.playlist-item .icon {
  font-size: 50px;
  color: #00796b;
  margin-bottom: 10px;
}

.playlist-item h3 {
  font-size: 1.5rem;
  color: #00796b;
  margin-bottom: 10px;
}

.video-container {
  position: absolute;
  top: 40px; /* Adjusted to move video down below banner */
  left: 0;
  width: 100%;
  height: calc(100vh - 40px); /* Adjusted to fit screen properly */
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.back-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 25px;
  font-size: 1rem;
  background-color: #00796b;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #005f56;
}

@media (max-width: 768px) {
  .playlist-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  .playlist-item h3 {
    font-size: 1.2rem;
  }
}
