/* BibleReader.css */

/* 1) Let html/body fill the window and allow scrolling if needed */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
}

/* 2) Main root container. Flex column. */
.bible-reader-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* ensures full page height */
    background-color: #000;
    color: #fff;
}

/* 3) The bible-container also fills space as a flex column. */
#bible-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* no margin/padding at top so controls appear flush */
    margin: 0;
    padding: 5;
}

/* 4) The controls bar: sticky at the top, no extra spacing */
#controls {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(17, 17, 17, 0.95);
    padding: 8px 16px;  /* minor padding for breathing room */
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);

    display: flex;
    /* ADDED flex-wrap so controls wrap on narrow screens */
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

/* If you want to hide controls, you can still toggle this class */
#controls.hidden {
    display: none;
}

/* Groups inside the controls */
#controls .controls-group {
    display: flex;
    align-items: center;
    /* ADDED flex-wrap to let group items wrap if needed */
    flex-wrap: wrap;
}

/* Basic input/select styling */
#controls select,
#controls input[type="text"] {
    font-size: 14px;
    padding: 5px 8px;
    margin: 0 5px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 4px;
    /* Slightly smaller min-width so they shrink more easily */
    min-width: 80px;
}

/* The content box: everything below the controls */
#content-box {
    display: flex;
    flex-direction: column;
    flex: 1;           /* fill remaining vertical space */
    overflow-y: auto;  /* scroll if verses exceed screen */
    padding: 10px 20px; /* some side padding */
}

.highlighted {
    color: yellow;
    background-color: rgba(255,255,0,0.2);
  }
  
  .word-highlight {
    color: yellow;
    background-color: rgba(255, 255, 0, 0.2);
    /* or any styling you prefer */
  }

  
/* 5) The yellow title below controls, centered */
#title {
    color: #f1c40f;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

/* Verse styling with a responsive font size */
.verse {
    margin: 10px 0;
    line-height: 1.4;
    word-wrap: break-word;
    /* Example approach: combine both vw + vh for dynamic resizing */
    font-size: calc(2vw + 2.5vh);
}

.verse-number {
    color: yellow;
    margin-right: 5px;
    font-weight: bold;
}

/* Navigation buttons at bottom/fixed */
.nav-button {
    position: fixed;
    bottom: 20px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 15px;
    border-radius: 50%;
    opacity: 0.7;
    transition: opacity 0.3s;
    z-index: 1000;
}

.nav-button:hover {
    opacity: 1;
}

#prev-btn {
    left: 45%;
    transform: translateX(-50%);
}

#next-btn {
    left: 55%;
    transform: translateX(-50%);
}

/* Share, Play/Pause, etc. */
#play-pause-btn {
    background-color: #555;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
}

#playback-rate-display {
    margin-left: 5px;
    font-size: 12px;
}

#slider-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

#lines-slider {
    width: 150px;
    margin: 0 10px;
}

#slider-value {
    width: 30px;
    text-align: center;
}

/* Share button */
#share-btn {
    background-color: #2980b9;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
}

/* Help button */
#help-btn {
    background-color: #8e44ad;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 18px;
    margin-left: 5px;
}

/* Tooltip */
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1001;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%; /* bottom of tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Help modal */
#help-modal {
    display: none; 
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgba(0,0,0,0.8);
}

#help-modal-content {
    background-color: #222;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    color: #fff;
    border-radius: 8px;
    position: relative;
}

#close-help {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

#close-help:hover,
#close-help:focus {
    color: #fff;
    cursor: pointer;
}

.autocomplete-active {
    background-color: #575757 !important;
    color: #fff;
}

/* MEDIA QUERY: On smaller screens, switch to a column layout */
@media (max-width: 600px) {
    #controls {
        flex-direction: column;
        align-items: flex-start;
    }

    #controls .controls-group {
        width: 100%;
        margin-bottom: 8px; /* spacing between groups */
        justify-content: flex-start;
    }

    #controls select,
    #controls input[type="text"] {
        width: 100%;
        max-width: none; /* let them fill the line */
        margin: 4px 0;
    }
}
