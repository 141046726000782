/* src/components/Gamification.css */

.gamification-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .gamification-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .gamification-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gamification-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .gamification-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Dashboard Section */
  .gamification-dashboard {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  
  .points-section, .badges-section {
    flex: 1 1 45%;
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .points-section h2, .badges-section h2 {
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .points {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color-dark);
  }
  
  .badges-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  .badge {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .badge-icon {
    font-size: 2rem;
    margin-bottom: 5px;
  }
  
  .badge-text {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  .starter {
    color: #ffd700; /* Gold */
  }
  
  .achiever {
    color: #c0c0c0; /* Silver */
  }
  
  .champion {
    color: #cd7f32; /* Bronze */
  }
  
  /* Activities Section */
  .gamification-activities {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .gamification-activities h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .activities-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
  }
  
  .activity-card {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .activity-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .activity-icon {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .activity-card h3 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .activity-card p {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 15px;
  }
  
  .earn-button {
    padding: 8px 16px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .earn-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .gamification-heading {
      font-size: 2.5rem;
    }
  
    .gamification-intro {
      font-size: 1rem;
    }
  
    .gamification-dashboard {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .points-section, .badges-section {
      flex: 1 1 80%;
    }
  
    .gamification-activities h2 {
      font-size: 1.8rem;
    }
  
    .activities-grid {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 15px;
    }
  
    .activity-card {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .gamification-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .gamification-heading {
      font-size: 1.8rem;
    }
  
    .gamification-intro {
      font-size: 0.9rem;
    }
  
    .gamification-activities h2 {
      font-size: 1.5rem;
    }
  
    .activities-grid {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    .activity-card {
      padding: 10px;
    }
  
    .share-buttons-container {
      flex-direction: column;
      align-items: center;
    }
  
    .share-button {
      width: 100%;
      justify-content: center;
    }
  }
  