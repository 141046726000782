/* src/components/Volunteer.css */

.volunteer-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .volunteer-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .volunteer-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .volunteer-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .volunteer-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Volunteer Roles */
  .volunteer-roles {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .volunteer-roles h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .roles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .role-card {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .role-card h3 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .role-card p {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  /* Nominate Yourself */
  .volunteer-nominate {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .volunteer-nominate h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .volunteer-nominate p {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 20px;
  }
  
  .nominate-button {
    padding: 12px 30px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .nominate-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  /* How We Support Volunteers */
  .volunteer-support {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .volunteer-support h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .volunteer-support ul {
    list-style-type: disc;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .volunteer-support li {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 10px;
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .volunteer-heading {
      font-size: 2.5rem;
    }
  
    .volunteer-intro {
      font-size: 1rem;
    }
  
    .volunteer-roles h2, .volunteer-nominate h2, .volunteer-support h2 {
      font-size: 1.8rem;
    }
  
    .roles-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 15px;
    }
  
    .role-card {
      padding: 15px;
    }
  
    .role-card h3 {
      font-size: 1.3rem;
    }
  
    .role-card p {
      font-size: 0.95rem;
    }
  
    .nominate-button {
      padding: 10px 25px;
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .volunteer-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .volunteer-heading {
      font-size: 1.8rem;
    }
  
    .volunteer-intro {
      font-size: 0.9rem;
    }
  
    .volunteer-roles h2, .volunteer-nominate h2, .volunteer-support h2 {
      font-size: 1.5rem;
    }
  
    .roles-grid {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    .role-card {
      padding: 10px;
    }
  
    .role-card h3 {
      font-size: 1.2rem;
    }
  
    .role-card p {
      font-size: 0.85rem;
    }
  
    .nominate-button {
      width: 100%;
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .volunteer-support ul {
      padding-left: 20px;
    }
  }
  