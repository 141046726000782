/* src/components/PrayerWall.css */

.prayer-wall-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .prayer-wall-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .prayer-wall-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .prayer-wall-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .prayer-wall-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Prayer Request Form */
  .prayer-request-form {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .prayer-request-form h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .prayer-request-form p {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 20px;
  }
  
  .submit-button {
    padding: 12px 30px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  /* Embedded Prayer Requests */
  .prayer-requests {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .prayer-requests h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .prayer-requests iframe {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .prayer-wall-heading {
      font-size: 2.5rem;
    }
  
    .prayer-wall-intro {
      font-size: 1rem;
    }
  
    .prayer-request-form h2 {
      font-size: 1.8rem;
    }
  
    .prayer-requests iframe {
      height: 350px;
    }
  }
  
  @media (max-width: 480px) {
    .prayer-wall-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .prayer-wall-heading {
      font-size: 1.8rem;
    }
  
    .prayer-wall-intro {
      font-size: 0.9rem;
    }
  
    .prayer-request-form h2 {
      font-size: 1.5rem;
    }
  
    .prayer-request-form p {
      font-size: 0.9rem;
    }
  
    .submit-button {
      width: 100%;
    }
  
    .prayer-requests iframe {
      height: 300px;
    }
  }
  