/* src/components/SocialMedia.css */

.social-media-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .social-media-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .social-media-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .social-media-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .social-media-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Social Media Links */
  .social-media-links {
    margin-bottom: 40px;
  }
  
  .social-links-heading {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .social-media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .social-tile {
    background-color: #f0f4f8;
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 20px;
    text-decoration: none;
    color: var(--text-color);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .social-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .social-text {
    font-size: 1rem;
    font-weight: bold;
  }
  
  /* Specific Icon Colors */
  .facebook {
    color: #3b5998;
  }
  
  .twitter {
    color: #1da1f2;
  }
  
  .instagram {
    color: #e4405f;
  }
  
  .youtube {
    color: #ff0000;
  }
  
  /* Share Buttons */
  .share-buttons {
    margin-bottom: 40px;
  }
  
  .share-buttons-heading {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .share-buttons-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .share-button {
    display: flex;
    align-items: center;
    background-color: #f0f4f8;
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 10px 20px;
    color: var(--text-color);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .share-button:hover {
    background-color: var(--primary-color);
    color: #ffffff;
  }
  
  .share-icon {
    font-size: 1.5rem;
    margin-right: 10px;
  }
  
  /* Specific Share Button Border Colors */
  .facebook-share {
    border-color: #3b5998;
  }
  
  .twitter-share {
    border-color: #1da1f2;
  }
  
  .instagram-share {
    border-color: #e4405f;
  }
  
  .youtube-share {
    border-color: #ff0000;
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .social-media-container {
      padding: 40px 20px;
    }
    
    .social-media-heading {
      font-size: 2.5rem;
    }
    
    .social-media-intro {
      font-size: 1rem;
    }
    
    .social-links-heading,
    .share-buttons-heading {
      font-size: 1.8rem;
    }
    
    .social-media-grid {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 15px;
    }
    
    .social-tile {
      padding: 15px;
    }
    
    .social-icon {
      font-size: 1.5rem;
    }
    
    .social-text {
      font-size: 0.9rem;
    }
    
    .share-buttons-container {
      gap: 15px;
    }
    
    .share-button {
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .social-media-container {
      width: 95%;
      padding: 20px 10px;
    }
    
    .social-media-heading {
      font-size: 1.8rem;
    }
    
    .social-media-intro {
      font-size: 0.9rem;
    }
    
    .social-links-heading,
    .share-buttons-heading {
      font-size: 1.5rem;
    }
    
    .social-media-grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 10px;
    }
    
    .social-tile {
      padding: 10px;
    }
    
    .social-icon {
      font-size: 1.2rem;
    }
    
    .social-text {
      font-size: 0.8rem;
    }
    
    .share-buttons-container {
      gap: 10px;
    }
    
    .share-button {
      padding: 8px 12px;
      font-size: 0.9rem;
    }
  }
  