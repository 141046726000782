/* src/components/Home.css */

/* Reset and Base Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Home Container */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

/* Welcome Section with Hover-triggered Color Change */
.welcome-section {
  width: 100%;
  height: 50vh;
  background: linear-gradient(45deg, rgba(0, 121, 107, 0.7), rgba(0, 150, 136, 0.7));
  background-size: 400% 400%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  transition: background-color 0.5s ease;
}

.welcome-section:hover {
  background: linear-gradient(45deg, rgba(0, 150, 136, 0.7), rgba(0, 121, 107, 0.7));
}

.welcome-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 121, 107, 0.4);
  z-index: 0;
}

.welcome-content {
  background-color: rgba(255, 255, 255, 0.70);
  
  padding: 20px 40px;
  border-radius: 10px;
  text-align: center;
  max-width: 800px;
   z-index: 1;
}

.welcome-content h1 {
  font-size: 3rem;
  color: #00796b;
  margin-bottom: 15px;
}

.welcome-content p {
  font-size: 1.5rem;
  color: #424242;
  margin-bottom: 20px;
}

.cta-button {
  padding: 10px 25px;
  font-size: 1.1rem;
  background-color: #00796b;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #005f56;
  transform: scale(1.1);
}


/* Menu Grid */
.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 90%;
  max-width: 1200px;
  padding: 40px 0;
}

.menu-item {
  background-color: #ffffff;
  border: 2px solid #d1eeeb;
  border-radius: 10px;
  padding: 20px;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  color: #00796b;
  text-decoration: none;
}

.menu-item:hover {
  transform: translateY(-10px) scale(1.05); /* Smooth scaling on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #e3f2fd;
}

.menu-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #00796b;
}

.menu-text {
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .menu-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
    padding: 20px 0;
  }

  .menu-item {
    padding: 15px;
  }

  .menu-icon {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  .menu-text {
    font-size: 1rem;
  }
}

/* Features Section */
.features-section {
  width: 90%;
  max-width: 1200px;
  padding: 40px 20px;
  background-color: #f5f5f5;
  text-align: center;
}

.features-section h2 {
  font-size: 2.5rem;
  color: #00796b;
  margin-bottom: 30px; /* Reduced margin-bottom from 40px to 30px */
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.feature-card {
  background-color: #ffffff;
  padding: 30px 20px;
  border: 2px solid #d1eeeb;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px) scale(1.05); /* Slight scale-up effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  font-size: 3rem;
  color: #00796b;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.8rem;
  color: #00796b;
  margin-bottom: 15px;
}

.feature-card p {
  font-size: 1rem;
  color: #424242;
  margin-bottom: 20px;
}

.learn-more {
  font-size: 1rem;
  color: #00796b;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.learn-more:hover {
  color: #005f56; /* Darker shade on hover */
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .feature-card {
    padding: 20px;
  }

  .feature-card h3 {
    font-size: 1.5rem;
  }

  .feature-card p {
    font-size: 0.9rem;
  }
}

/* Testimonials Section */
.testimonials-section {
  width: 90%;
  max-width: 1200px;
  padding: 40px 20px;
  background-color: #f5f5f5;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  color: #00796b;
  margin-bottom: 30px; /* Reduced margin-bottom from 40px to 30px */
}

.testimonials-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.testimonial-card {
  background-color: #ffffff;
  padding: 20px 30px;
  border: 2px solid #00796b;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-icon {
  font-size: 1.5rem;
  color: #e53935;
  margin-bottom: 15px;
}

.testimonial-card p {
  font-size: 1rem;
  color: #424242;
  margin-bottom: 15px;
  font-style: italic;
}

.testimonial-card h4 {
  font-size: 1.1rem;
  color: #00796b;
}

@media (max-width: 768px) {
  .testimonials-grid {
    flex-direction: column;
    gap: 20px;
  }

  .testimonial-card {
    width: 100%;
  }
}

/* Inspirational Section */
.inspirational-section {
  width: 100%;
  padding: 40px 20px;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.inspirational-section h2 {
  font-size: 2.5rem;
  color: #00796b;
  margin-bottom: 20px;
}

.inspirational-section p {
  font-size: 1.2rem;
  color: #424242;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.inspirational-section .cta-button {
  padding: 10px 25px;
  font-size: 1.1rem;
  background-color: #00796b;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.inspirational-section .cta-button:hover {
  background-color: #005f56;
}

@media (max-width: 768px) {
  .inspirational-section h2 {
    font-size: 2rem;
  }

  .inspirational-section p {
    font-size: 1rem;
  }
}

