/* src/components/Help.css */

.help-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  /* Header Section */
  .help-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .help-heading {
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .help-icon {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color); /* Green theme color */
  }
  
  .help-intro {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Features Overview */
  .features-overview {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .features-overview h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .feature-card {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feature-card h3 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .feature-card p {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  /* Goals */
  .help-goals {
    background-color: #ffffff; /* White background */
    border: 2px solid var(--secondary-color); /* Light blue border */
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .help-goals h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .help-goals ul {
    list-style-type: disc;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .help-goals li {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 10px;
  }
  
  /* Frequently Asked Questions */
  .faq-section {
    margin-bottom: 40px;
  }
  
  .faq-section h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .faq-item {
    background-color: #f0f4f8;
    border: 2px solid var(--secondary-color);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .faq-item h3 {
    font-size: 1.2rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .faq-item p {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  /* Contact Support */
  .contact-support {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-support h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .contact-support p {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  .contact-support a {
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .help-heading {
      font-size: 2.5rem;
    }
  
    .help-intro {
      font-size: 1rem;
    }
  
    .features-overview h2, .help-goals h2, .faq-section h2, .contact-support h2 {
      font-size: 1.8rem;
    }
  
    .features-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 15px;
    }
  
    .feature-card {
      padding: 15px;
    }
  
    .feature-card h3 {
      font-size: 1.3rem;
    }
  
    .feature-card p {
      font-size: 0.95rem;
    }
  
    .faq-item h3 {
      font-size: 1.1rem;
    }
  
    .faq-item p {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .help-container {
      width: 95%;
      padding: 20px 10px;
    }
  
    .help-heading {
      font-size: 1.8rem;
    }
  
    .help-intro {
      font-size: 0.9rem;
    }
  
    .features-overview h2, .help-goals h2, .faq-section h2, .contact-support h2 {
      font-size: 1.5rem;
    }
  
    .features-grid {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    .feature-card {
      padding: 10px;
    }
  
    .feature-card h3 {
      font-size: 1.2rem;
    }
  
    .feature-card p {
      font-size: 0.85rem;
    }
  
    .faq-item h3 {
      font-size: 1rem;
    }
  
    .faq-item p {
      font-size: 0.85rem;
    }
  }
  