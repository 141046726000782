/* src/components/ChatBubble.css */

/* Chat Bubble Icon */
.chat-bubble {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #00796b;
    color: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    z-index: 1000;
    font-size: 1.5rem;
  }
  
  .chat-bubble:hover {
    background-color: #005f56;
    transform: scale(1.1);
  }
  
  /* Chat Window */
  .chat-window {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 300px;
    max-height: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 1000;
  }
  
  .chat-header {
    background-color: #00796b;
    color: #ffffff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .chat-header h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .close-icon {
    cursor: pointer;
    font-size: 1rem;
  }
  
  .chat-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f5f5f5;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 15px;
    max-width: 80%;
    word-wrap: break-word;
    font-size: 0.9rem;
  }
  
  .message.user {
    background-color: #dcf8c6;
    align-self: flex-end;
  }
  
  .message.assistant {
    background-color: #ffffff;
    align-self: flex-start;
    border: 1px solid #00796b;
  }
  
  /* Chat Input */
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .chat-input textarea {
    flex: 1;
    resize: none;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
    height: 40px;
  }
  
  .chat-input button {
    background-color: #00796b;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    font-size: 1rem;
  }
  
  .chat-input button:disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
  }
  
  .chat-input button:hover:not(:disabled) {
    background-color: #005f56;
  }
  
  /* Character Count */
  .character-count {
    text-align: right;
    padding: 0 10px 10px 10px;
    font-size: 0.8rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .chat-window {
      width: 90%;
      right: 5%;
      bottom: 80px;
    }
  }
  