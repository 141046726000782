/* src/components/UnderDevelopment.css */

.under-dev-container {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 100px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  /* Main Content */
  .under-dev-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .under-dev-icon {
    font-size: 5rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .under-dev-content h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .under-dev-content p {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  /* Navigation Link Back */
  .navigation-link-back {
    text-align: center;
  }
  
  .navigation-link-back .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--primary-color); /* Green background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .navigation-link-back .cta-button:hover {
    background-color: var(--primary-color-dark); /* Darker green on hover */
  }
  
  .back-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .under-dev-container {
      padding: 80px 20px;
    }
  
    .under-dev-icon {
      font-size: 4rem;
    }
  
    .under-dev-content h1 {
      font-size: 2rem;
    }
  
    .under-dev-content p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .under-dev-container {
      width: 95%;
      padding: 60px 10px;
    }
  
    .under-dev-icon {
      font-size: 3rem;
    }
  
    .under-dev-content h1 {
      font-size: 1.8rem;
    }
  
    .under-dev-content p {
      font-size: 0.9rem;
    }
  
    .navigation-link-back .cta-button {
      width: 100%;
      justify-content: center;
    }
  }
  