body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 


/* src/index.css or src/App.css */

:root {
  --primary-color: #00796b; /* Green */
  --primary-color-dark: #005b5b; /* Darker Green for hover states */
  --secondary-color: #90caf9; /* Light Blue */
  --text-color: #424242; /* Dark Gray */
  --background-color: #f5f5f5; /* Light Gray */
  --highlight-color: yellow; /* For text highlighting */
  --button-hover-color: #005b5b;
  --error-color: #e53935; /* Red for errors */
}

/* Apply global styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-family: inherit;
}

/* Optional: Global box-sizing */
*, *::before, *::after {
  box-sizing: border-box;
}
