/* src/components/Marketplace.css */

/* Container */
.marketplace-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
}

/* Header Section */
.marketplace-header {
  text-align: center;
  margin-bottom: 40px;
}

.marketplace-heading {
  font-size: 3rem;
  color: var(--primary-color); /* Green theme color */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketplace-icon {
  margin-right: 10px;
  font-size: 3rem;
  color: var(--primary-color); /* Green theme color */
}

.marketplace-intro {
  font-size: 1.2rem;
  color: var(--text-color);
}

/* Categories Grid */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

/* Category Card */
.category-card {
  background-color: #ffffff; /* White background */
  border: 2px solid var(--secondary-color); /* Light blue border */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.category-icon {
  font-size: 3rem;
  color: var(--primary-color); /* Green theme color */
  margin-bottom: 15px;
}

.category-title {
  font-size: 1.8rem;
  color: var(--primary-color); /* Green theme color */
  margin-bottom: 15px;
}

.category-description {
  font-size: 1.1rem;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 20px;
  padding: 0 5px; /* Optional: Add padding for better readability */
}

/* Amazon Widget */
.amazon-widget {
  width: 100%;
  min-height: 10px; /* Adjust as needed */
  margin-top: 10px;
}

/* Navigation Link Back */
.navigation-link-back {
  margin-top: 40px;
  text-align: center;
}

.navigation-link-back .cta-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--primary-color); /* Green background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.navigation-link-back .cta-button:hover {
  background-color: var(--primary-color-dark); /* Darker green on hover */
}

.back-icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 992px) {
  .marketplace-heading {
    font-size: 2.5rem;
  }

  .marketplace-intro {
    font-size: 1rem;
  }

  .categories-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }

  .category-card {
    padding: 15px;
  }

  .category-icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  .category-title {
    font-size: 1.5rem;
  }

  .category-description {
    font-size: 0.95rem;
    margin-bottom: 15px;
  }

  .amazon-widget {
    min-height: 180px;
  }
}

@media (max-width: 768px) {
  .marketplace-heading {
    font-size: 2rem;
  }

  .marketplace-intro {
    font-size: 0.95rem;
  }

  .categories-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .category-card {
    padding: 15px;
  }

  .category-icon {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  .category-title {
    font-size: 1.3rem;
  }

  .category-description {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  .amazon-widget {
    min-height: 200px;
  }
}

@media (max-width: 480px) {
  .marketplace-container {
    width: 95%;
    padding: 20px 10px;
  }

  .marketplace-heading {
    font-size: 1.8rem;
  }

  .marketplace-intro {
    font-size: 0.9rem;
  }

  .categories-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .category-card {
    padding: 10px;
  }

  .category-icon {
    font-size: 1.8rem;
    margin-bottom: 5px;
  }

  .category-title {
    font-size: 1.2rem;
  }

  .category-description {
    font-size: 0.85rem;
    margin-bottom: 10px;
  }

  .amazon-widget {
    min-height: 200px;
  }

  .navigation-link-back .cta-button {
    width: 100%;
    justify-content: center;
  }
}
