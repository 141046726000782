/* src/App.css */

/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html, #root {
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f2f5;
}

/* Header Styles */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
}

.header.home {
  background-color: #ecf0f7;
  color: #7c7a7a;
}

.header.other {
  background-color: black;
  color: #7c7a7a;
}

body {
  padding-top: 40px; /* Adjust according to header height */
}

.logo {
  font-size: 1.0rem;
  font-weight:normal ;
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo-icon {
  width: 24px;
  height: 24px;
}

.hidden-text {
  visibility: hidden;
}

/* Not Found Page */
.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 2rem;
  color: #ff5252;
}

/* Additional global styles can be added here */
